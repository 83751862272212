"use client";
import { commonFetch, post } from "@/utils/commonFetch";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
const Footer = () => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showTerms, setTerms] = useState(false);
  const [error, setError] = useState("");
  const [isInstaHovered, setInstaHovered] = useState(false);
  const [isFaceHovered, setFaceHovered] = useState(false);
  const [termsData, setTermsData] = useState<any>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await commonFetch.get("terms-and-condition");
        setTermsData(response);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);
  const handleClick = () => {
    setShowInput(true);
  };

  const handleInputChange = (e: any) => {
    const email = e.target.value;
    setInputValue(email);
  };

  const handleEmailIconClick = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (inputValue === "" || !emailRegex.test(inputValue)) {
      setError("Invalid email format");
    } else {
      post("/subscriptions", { data: { email: inputValue } })
        .then((response) => {
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 3000);
          setShowInput(false);
          setError("");
        })
        .catch((error) => {
          console.error("Error posting");
        });
    }
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleEmailIconClick();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setTerms(false);
      }
    };
    if (showTerms) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTerms, popupRef]);

  return (
    <>
      <div
        id="overlay"
        className={`${showTerms ? "overlay z-[100]" : "hidden"}`}
      ></div>
      <footer className="bg-black text-white p-5 xl:h-[8vh] footer-height">
        <div className="relative container mx-auto flex flex-col justify-start items-start md:flex-row md:justify-between md:items-center sm:mt-0 -mt-[10px] footer-wrapper">
          <div className="text-left sm:text-center mb-4 sm:mb-0 sm:mt-0 mt-[10px] xl:-ml-[25px]">
            <p className="footer-copyright md:w-[418px] xl:w-auto">
              Copyright &copy; Saradar Collection 2023. All rights reserved.{" "}
              <span
                className="footer-terms block md:inline xl:absolute xl:w-[170px] xl:pr-[50px]"
                onClick={() => setTerms(true)}
              >
                Terms and conditions
              </span>
            </p>
          </div>
          <div className="text-center flex justify-center items-center mb-4 sm:mb-0">
            <p className="footer-followUs xl:mr-2 ml-[5px] md:w-[100px]">
              Follow us on
            </p>
            <div className="relative ml-6">
              <div
                onMouseEnter={() => setFaceHovered(true)}
                onMouseLeave={() => setFaceHovered(false)}
              >
                {isFaceHovered ? (
                  <Link href="https://www.facebook.com/SaradarCollection/">
                    <Image
                      src="/assets/icons/facebook-green.svg"
                      alt="facebook Green"
                      width={800}
                      height={800}
                      className="w-full cursor-pointer"
                    />
                  </Link>
                ) : (
                  <Link href="https://www.facebook.com/SaradarCollection/">
                    <Image
                      src="/assets/icons/facebook.svg"
                      alt="facebook Green"
                      width={800}
                      height={800}
                      className="w-full cursor-pointer"
                    />
                  </Link>
                )}
              </div>
            </div>
            <div className="relative ml-4">
              <div
                onMouseEnter={() => setInstaHovered(true)}
                onMouseLeave={() => setInstaHovered(false)}
              >
                {isInstaHovered ? (
                  <Link href="https://www.instagram.com/saradarcollection/?hl=en">
                    <Image
                      src="/assets/icons/instagram-green.svg"
                      alt="Instagram Green"
                      width={800}
                      height={800}
                      className="w-full cursor-pointer"
                    />
                  </Link>
                ) : (
                  <Link href="https://www.instagram.com/saradarcollection/?hl=en">
                    <Image
                      src="/assets/icons/instagram.svg"
                      alt="Instagram Green"
                      width={800}
                      height={800}
                      className="w-full cursor-pointer"
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="text-center sm:text-right md:w-[250px] newsletter">
            {showInput ? (
              <div className="relative">
                <div
                  className="absolute top-1 right-0 flex items-center"
                  onClick={handleEmailIconClick}
                >
                  <Image
                    src="/assets/icons/email.svg"
                    alt="email"
                    width={800}
                    height={800}
                    className="w-full cursor-pointer"
                  />
                </div>
                <input
                  type="email"
                  value={inputValue}
                  onKeyDown={handleInputKeyDown}
                  onChange={handleInputChange}
                  className={`border-b ${
                    error ? "border-red-500" : "border-white"
                  } bg-transparent focus:outline-none pr-6`}
                  placeholder="Enter your email"
                />
                {error && (
                  <p className="text-red-500 text-[10px] absolute lg:ml-12 md:ml-[45px]">
                    {error}
                  </p>
                )}
              </div>
            ) : (
              <p
                className="footer-newsletter xl:-mr-[25px]"
                onClick={handleClick}
              >
                Subscribe to our newsletter
              </p>
            )}
          </div>
          {showPopup && (
            <div className="absolute text-black p-8 border bg-white w-[270px] md:bottom-[45px] bottom-[135px] md:right-[0] footer-popup">
              <div className="flex flex-col items-center gap-4">
                <Image
                  src="/assets/icons/tick-circle.svg"
                  alt="email"
                  width={30}
                  height={30}
                />
                <p className="text-center bdy-txt-1">
                  You have successfully subscribed to our newsletter
                </p>
              </div>
            </div>
          )}
          {showTerms && (
            <div
              ref={popupRef}
              className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] h-[84%] bg-white border p-8 overflow-y-auto z-[100]"
            >
              <div className="text-black">
                <h1 className="text-center whitespace-nowrap mb-8">
                  {termsData?.title}
                </h1>

                <ReactMarkdown
                  remarkPlugins={[remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                  className="bdy-txt-3"
                >
                  {termsData?.text}
                </ReactMarkdown>
              </div>

              <div className="md:top-[20px] md:mr-[20px] top-[10px] mr-[10px] right-0 absolute">
                <button
                  className="md:p-[10px] p-0"
                  onClick={() => setTerms(false)}
                >
                  <Image
                    src="/assets/icons/close.svg"
                    alt="Close"
                    width={25}
                    height={20}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </footer>
    </>
  );
};

export default Footer;
