process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
export const get = async (path: string, params = null) => {
  const requestOptions = {
    method: "GET",
  };
  let url: string;
  if (params !== null) {
    url = `${process.env.NEXT_PUBLIC_API_URL}/${path}?${params}`;
  } else {
    url = `${process.env.NEXT_PUBLIC_API_URL}/${path}`;
  }
  return await helperFetch(url, requestOptions);
};

export const post = async (url: string, body: object) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return await helperFetch(
    process.env.NEXT_PUBLIC_API_URL + url,
    requestOptions,
  );
};

export const put = async (url: string, body: object) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  return await helperFetch(
    process.env.NEXT_PUBLIC_API_URL + url,
    requestOptions,
  );
};

// prefixed with underscored because delete is a reserved word in javascript
export const _delete = async (url: string) => {
  const requestOptions = {
    method: "DELETE",
  };
  return await helperFetch(
    process.env.NEXT_PUBLIC_API_URL + url,
    requestOptions,
  );
};

export const postToBackend = async (url: string, body: object) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.NEXT_PUBLIC_XAPIKEY || "",
    },
    body: JSON.stringify(body),
  };

  return await helperFetch(
    process.env.NEXT_PUBLIC_BACKEND_API_URL + url,
    requestOptions,
  );
};

export const getFromBackend = async (path: string, params = null) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "*/*",
      "x-api-key": process.env.NEXT_PUBLIC_XAPIKEY || "",
    },
  };
  let url: string;
  if (params !== null) {
    url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}${path}?${params}`;
  } else {
    url = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}${path}`;
  }
  return await helperFetch(url, requestOptions);
};

// helper functions

const helperFetch = async (
  url: RequestInfo | URL,
  requestOptions?: RequestInit,
) => {
  // process.stdout.write(
  //   "\x1b[33m" + requestOptions?.method + " | url: " + "\x1b[89m"
  // );
  console.time(String(url));
  const urlString = url instanceof URL ? url.toString() : url;
  const response = await fetch(urlString, requestOptions);

  console.timeEnd(String(url));
  const data = response.json();
  if (!response.ok) {
    const error = "Something Went Wrong: " + response.statusText;
    console.error(error);
    // This will activate the closest `error.js` Error Boundary
    throw new Error("Failed to fetch data! " + error);
  }
  return data;
};
export const commonFetch = {
  get,
  post,
  put,
  delete: _delete,
  postToBackend,
  getFromBackend,
};
