"use client";
// DataContext.js
import React, { createContext, useContext, useReducer } from "react";
// Initial state
const initialState = {
  sharedData: null,
};
// Actions
const SET_SHARED_DATA = "SET_SHARED_DATA";
// Reducer
const dataReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_SHARED_DATA:
      return { ...state, sharedData: action.payload };
    default:
      return state;
  }
};
// Create Context
const DataContext = createContext({
  state: initialState,
  setSharedData: (data: any) => {}, // Provide a default value for setSharedData
});
// Create Provider
export const DataProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const setSharedData = (data: any) => {
    dispatch({ type: SET_SHARED_DATA, payload: data });
  };
  return (
    <DataContext.Provider value={{ state, setSharedData }}>
      {children}
    </DataContext.Provider>
  );
};
// Create Hook for consuming the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
