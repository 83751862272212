"use client";
import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useData } from "@/components/shared/DataContext";

const routes = [
  {
    id: 1,
    path: "/about",
    text: "About",
  },
  {
    id: 2,
    path: "/collection",
    text: "The Collection",
  },
  {
    id: 3,
    path: "/art-writings",
    text: "Art writings",
    submenu: [
      {
        text: "Archive of Texts",
        path: "/text-archives",
      },
      {
        text: "Essays",
        path: "/essays",
      },
      {
        text: "Books",
        path: "/books",
      },
    ],
  },
  {
    id: 4,
    path: "/programs",
    text: "Programs",
    submenu: [
      {
        text: "Perspective",
        path: "/perspective",
      },
      {
        text: "Words on Works",
        path: "/words-on-works",
      },
      {
        text: "Loans",
        path: "/loans",
      },
    ],
  },
  {
    id: 5,
    path: "/contact-us",
    text: "Contact us",
  },
];

// export const MobileMenu = ({ isOpen }: { isOpen: boolean }) => {
//   return isOpen;
// };
export const MobileMenu = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { state, setSharedData } = useData();
  const pathname = usePathname();
  const [submenuVisible, setSubmenuVisible] = useState(0);
  const toggleDropdown = (id: number) => {
    const index = routes.findIndex((route) => route.id === id);
    if (routes[index].submenu?.length) {
      setSubmenuVisible(id);
    }
    if (submenuVisible === id) {
      setSubmenuVisible(0); // Clicking on the already opened submenu collapses it
    }
  };

  useEffect(() => {
    setSharedData(isOpen);
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } bg-white fixed top-0 left-0 w-full z-20`}
    >
      <div className="p-4 space-y-4">
        <Link href="/" onClick={onClose}>
          <Image
            src="/saradar-collection.svg"
            alt="Logo"
            width={170}
            height={50}
            className="cursor-pointer mt-[10px] h-[50px] w-[130px]"
          />
        </Link>
      </div>
      <ul className="p-4 space-y-6 pb-9 border-b border-[#CECECE]">
        {routes?.map((route) => (
          <li key={route.id}>
            <Link
              href={route?.submenu ? "" : route?.path}
              onClick={() => {
                if (!route.submenu) {
                  onClose();
                }
              }}
            >
              <p
                className={`header-title relative group ${
                  pathname.includes(route?.path)
                    ? "text-[#015549]"
                    : "text-black"
                }`}
                onMouseOver={(event) => toggleDropdown(route.id)}
                onClick={(event) => toggleDropdown(route.id)}
              >
                {route?.text}
              </p>
            </Link>

            {route?.submenu?.length && submenuVisible === route.id && (
              <div onMouseLeave={(event) => toggleDropdown(route.id)}>
                <div className="relative m-[25px]">
                  <div className="text-left flex flex-col gap-4">
                    {route?.submenu?.map((subroute, index) => (
                      <div key={index}>
                        <Link
                          href={route?.path + subroute?.path}
                          onClick={onClose}
                          className={`header-sub-title ${
                            pathname.includes(subroute?.path)
                              ? "text-[#015549]"
                              : "text-black"
                          }`}
                        >
                          {subroute?.text}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>

      <button
        className="absolute top-10 right-4 text-black cursor-pointer -mt-[5px]"
        onClick={onClose}
      >
        <Image
          src="/assets/icons/close.svg"
          alt="Close"
          width={25}
          height={20}
        />
      </button>
    </div>
  );
};

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();

  const [submenuVisible, setSubmenuVisible] = useState(0);
  const toggleDropdown = (id: number) => {
    setSubmenuVisible((prevId) => (prevId === id ? 0 : id));
  };

  const isHomePage = pathname === "/";
  return (
    <header
      className={`bg-${
        isHomePage
          ? "transparent top-[5px]"
          : "white md:border border-b top-[0px] sec-header"
      } md:border-[#CECECE] fixed  left-0 w-full z-30`}
      onMouseLeave={(event) => setSubmenuVisible(0)}
    >
      {isHomePage ? (
        <div className="mt-[105px] ml-[0px] w-[100%] md:ml-[50px] xl:ml-[50px] absolute lg:w-[95%] md:w-[92%] header-border">
          <hr />
        </div>
      ) : null}

      <nav className="nav-bar mx-auto flex items-center justify-between p-4 pb-6 md:ml-[50px] xl:ml-[135px] sm:w-[85%] md:w-[92%] lg:w-[90%]">
        <div className="flex items-center xl:-ml-[100px] md:-ml-[20px]">
          <Link href="/">
            <Image
              onClick={() => setSubmenuVisible(0)}
              src={isHomePage ? "/saradar.svg" : "/saradar-collection.svg"}
              alt="Logo"
              width={150}
              height={65}
              className={`cursor-pointer h-[50px] w-[130px] lg:h-[65px] md:w-full ${
                isHomePage ? "mt-[5px] home-logo" : "mt-[10px] logo"
              }`}
            />
          </Link>
        </div>
        <button
          className="md:hidden text-black cursor-pointer mt-[10px]"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <Image
            src={
              isHomePage
                ? "/assets/icons/burger-white.svg"
                : "/assets/icons/burger-black.svg"
            }
            alt="Burger Menu"
            width={30}
            height={30}
          />
        </button>

        <ul className="hidden md:flex lg:space-x-10 xl:space-x-20 md:space-x-4 header-titles">
          {routes?.map((route) => (
            <li key={route?.id}>
              <Link href={route?.submenu ? "" : route?.path}>
                <p
                  className={` header-title lg:text-[19px] relative group ${
                    !isHomePage ? " hover:text-[#015549]" : ""
                  } ${
                    pathname.includes(route.path)
                      ? "text-[#015549]"
                      : `text-${isHomePage ? "white" : "black"}`
                  }`}
                  onMouseOver={(event) => toggleDropdown(route.id)}
                >
                  {route?.text}
                  <span
                    className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 top-8  ${
                      pathname.includes(route.path) ||
                      submenuVisible === route.id
                        ? "opacity-100"
                        : ""
                    }`}
                    onMouseOver={(event) => toggleDropdown(route.id)}
                  >
                    {isHomePage ? (
                      <Image
                        src="/assets/icons/dot-white.svg"
                        alt="My SVG"
                        width={10}
                        height={10}
                      />
                    ) : (
                      <Image
                        src="/assets/icons/dot-green.svg"
                        alt="My SVG"
                        width={10}
                        height={10}
                      />
                    )}
                  </span>
                </p>
              </Link>

              {route?.submenu?.length && (
                <div onMouseLeave={(event) => toggleDropdown(route.id)}>
                  {submenuVisible === route.id && (
                    <div className="relative">
                      <div
                        className={`
                          absolute top-[12px] xl:top-0 left-0 flex flex-col items-center justify-evenly w-[210px] h-[130px] border-b-1 border-r-1 border-l-1 
                          ${
                            isHomePage
                              ? `bg-transparent text-white border border-white border-t-0 xl:mt-[44px] lg:top-[5px] home-sub-menu ${
                                  route.id == 3 ? "-ml-[50px]" : "-ml-[60px]"
                                }`
                              : `bg-white text-black border border-[#CECECE] xl:mt-[50px] lg:top-[8px] pages-sub-menu ${
                                  route.id == 3 ? "-ml-[50px]" : "-ml-[60px]"
                                }`
                          }`}
                      >
                        {route?.submenu?.map((subroute, index) => (
                          <div key={index}>
                            <Link
                              onClick={() => setSubmenuVisible(0)}
                              href={route?.path + subroute?.path}
                              className={`header-sub-title ${
                                isHomePage
                                  ? "hover:underline"
                                  : "hover:text-[#015549]"
                              }`}
                            >
                              {subroute?.text}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <MobileMenu
        isOpen={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(!mobileMenuOpen)}
      />
    </header>
  );
};

export default Header;
